.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.white {
    color: var(--color-black);
    background-color: var(--color-white);
    background-image: url(/images/background-quote-white.svg);
  }

  &.green {
    color: var(--color-white);
    background-color: var(--color-green-500);
    background-image: url(/images/background-quote-green.svg);
  }

  &.blue {
    color: var(--color-white);
    background-color: var(--color-blue-500);
    background-image: url(/images/background-quote-blue.svg);
  }

  &.turquoise {
    color: var(--color-white);
    background-color: var(--color-turquoise-500);
    background-image: url(/images/background-quote-turquoise.svg);
  }

  & > .details {
    flex: 0 0 auto;
  }
}

.details {
  color: var(--color-white);
  padding: var(--size-md);

  @media (--viewport-md) {
    padding: var(--size-lg);
  }
}
