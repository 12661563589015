.page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .header {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }

  & > .map {
    flex-grow: 1;
  }

  & > .story {
    position: absolute;
    bottom: 5vh;
    left: 0;
    width: 100%;
  }

  &.isDark {
    color: var(--color-white);
  }
}

.map {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > * {
    flex-grow: 1;
  }
}

.story {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  will-change: transform;

  & > * {
    width: 100%;
  }
}
