.page {
  position: relative;
  z-index: 0;
  background-color: var(--color-white);
  padding: 10vh 0 10vh;

  & > .hero {
    margin-bottom: 5vh;
  }

  & > .waterLine {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  & > .logo {
    z-index: 1;
    position: absolute;
    top: calc(var(--size-lg) + var(--size-md));
    right: var(--size-lg);
    width: 70px;

    @supports (right: max(var(--size-lg), 50%)) {
      right: 10rem;
      width: 100px;
    }
  }
}

.logoLayout {
  & > .logo {
    width: 70px;
    margin-left: auto;

    @media (--viewport-md) {
      width: 100px;
    }
  }
}

.heroLayout {
  display: flex;
  flex-direction: column;

  & > :first-child {
    order: 1;
    margin-top: var(--size-sm);
  }
}

.waterLine {
  color: var(--color-gray-50);
}
