@value _breakpointSm: 480px;
@value _breakpointMd: 768px;
@value _breakpointLg: 1024px;
@value _breakpointXl: 1440px;

:root {
  --breakpoint-sm: _breakpointSm;
  --breakpoint-md: _breakpointMd;
  --breakpoint-lg: _breakpointLg;
  --breakpoint-xl: _breakpointXl;
}

:export {
  breakpointSm: _breakpointSm;
  breakpointMd: _breakpointMd;
  breakpointLg: _breakpointLg;
  breakpointXl: _breakpointXl;
}

@custom-media --viewport-sm screen and (min-width: 480px);
@custom-media --viewport-md screen and (min-width: 768px);
@custom-media --viewport-lg screen and (min-width: 1024px);
@custom-media --viewport-xl screen and (min-width: 1440px);
