.component {
  background: var(--color-white);
  padding: var(--size-md);
  color: var(--color-black);
  border-radius: var(--size-xs);
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-items: center;

  & > .button {
    justify-self: flex-end;
  }
}

.description {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base-400);
  line-height: 1;
}
