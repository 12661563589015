.component {
  position: relative;
  z-index: 0;

  & > .chapter {
    height: 100%;
  }

  & > .ui {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.ui {
  display: flex;
  flex-direction: column;

  & > * {
    max-width: 100%;
  }
}

.chapter {
  overflow: hidden;
}

.componentHeader {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  color: white;
  will-change: transform;
  padding: var(--size-md);

  @media (--viewport-sm) {
    padding: var(--size-lg);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  & > * {
    max-width: 100%;
  }

  & > :not(:last-child) {
    @media (--viewport-sm) {
      margin-bottom: var(--size-xxxs);
    }
  }

  & > .pagination {
    order: -1;
    margin-bottom: var(--size-sm);
  }
}

.descriptionText {
  font-size: var(--font-size-18);
}

.descriptionAndClose {
  display: flex;

  & > .description {
    flex: 1 1 auto;
  }

  & > .close {
    align-self: flex-start;
    flex: 0 0 auto;
    margin: calc(-1 * var(--size-sm)) calc(-1 * var(--size-sm)) calc(-1 * var(--size-sm)) 0;
  }
}

.componentPagination {
  display: flex;

  & > * {
    flex: 1 1 auto;
  }

  & > :not(:first-child) {
    margin-left: 4px;

    @media (--viewport-xs) {
      margin-left: 8px;
    }
  }
}

.item {
  background-color: rgba(255, 255, 255, 0.3);
  height: 2px !important;
  overflow: hidden;

  & > .fill {
    width: 100%;
    height: 100%;
  }
}

.fill {
  background-color: white;
  transform-origin: center left;
}

.close {
  padding: var(--size-sm);
  color: white;

  & > * {
    display: block;
    width: 1.6rem;
    height: 1.6rem;

    @media (--viewport-lg) {
      width: auto;
      height: auto;
    }
  }
}

.description {
  & > .heading {
    margin-bottom: 2px;
  }
}
