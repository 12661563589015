.component_root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  color: var(--color-white);

  & > .panel {
    height: 90%;
    margin-top: auto;
    width: 100%;
    max-width: var(--max-width);
  }
}

.panel {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--color-turquoise-500);
  pointer-events: auto;
  border-radius: var(--size-xs) var(--size-xs) 0 0;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10%;
    background-color: inherit;
  }

  & > .handle {
    flex-shrink: 0;

    &.relativeToParent {
      position: static;
      z-index: 1;
    }
  }

  & > .content {
    flex: 1 1 auto;
  }
}

.handle {
  height: 10vh !important;
  max-height: 60px !important;
  position: relative;
  z-index: 0;
  line-height: 0;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color var(--duration-xs);

  &:active {
    cursor: grabbing;
    outline: 0;
  }

  &:focus {
    outline: 1px var(--color-gray-10);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px teal;
  }

  &::-webkit-scrollbar-thumb {
    background-color: hotpink;
  }

  /* We use before and after, because padding gets lost in a scrollable element. */
  &::after {
    display: block;
    content: '';
    width: 100%;
    height: var(--size-md);

    @media (--viewport-lg) {
      height: var(--size-lg);
    }
  }
}
