.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-lg);
  }
}

.list {
  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }
}
