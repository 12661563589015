.component_rootBase {
  position: fixed;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.5);
  -webkit-overflow-scrolling: touch;

  & > .container {
    height: 100%;
    width: 100%;
  }

  & > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.componentPortrait {
  & > .container {
    max-width: var(--max-width);
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .content {
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
  }
}

.content {
  & > * {
    height: 100%;
  }
}

.overlay {
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.ui {
  display: flex;
  pointer-events: none;
  justify-content: flex-end;
  padding: var(--size-md);
  color: var(--color-white);

  & > * {
    pointer-events: auto;
  }
}
