:root {
  --font-family-base: 'Montserrat', sans-serif;
  --font-weight-base-400: 400;
  --font-weight-base-500: 500;
  --font-weight-base-700: 700;

  --line-height-small: 1;
  --line-height-normal: 1.5;

  --font-size-12: 1.2rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-24: 2.4rem;
  --font-size-32: 3.2rem;
  --font-size-52: 5.2rem;
  --font-size-74: 7.4rem;
  --font-size-85: 8.5rem;
}
