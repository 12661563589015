.component {
  & > .slide {
    height: 100%;
  }
}

.slide {
  flex: 0 0 auto;

  & > * {
    height: 100%;
  }
}
