.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-90);

  & > .video {
    height: 100%;
    flex: 1 1 auto;
  }

  & > .text {
    position: absolute;
    bottom: 0;
    z-index: 1;
    flex: 0 0 auto;
  }
}

.text {
  color: var(--color-white);
  padding: var(--size-md);

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }

  @media (--viewport-sm) {
    padding: var(--size-lg);
  }
}

.video {
  position: relative;

  & > .videoLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoLayout {
  object-fit: cover;
}

