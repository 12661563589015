.component {
  position: relative;
  z-index: 0;

  & > .floorPin {
    top: 0;
    left: 0;
    position: absolute;
  }
}

.floorButton {
  pointer-events: none;
  color: transparent;

  &:focus {
    outline: 0;
  }
}

.floorPin {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

[data-context-visible='true'] .floorPin {
  pointer-events: auto;
}

.componentImage {
  pointer-events: none;
}

[data-context-visible='true'] .componentImage :not(g):not(svg) {
  pointer-events: auto;
}
