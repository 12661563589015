.component {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-md) 0;
  font-weight: var(--font-weight-base-700);

  & > .heading {
    justify-self: flex-end;
  }
}
