.componentCircleBase {
  justify-content: space-around;
  font-weight: var(--font-weight-600);
  align-items: center;
  font-size: var(--font-size-16);
  background-color: var(--color-orange-500);
  color: var(--color-white);
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  line-height: var(--line-height-normal);

  @media (--viewport-md) {
    width: 80px !important;
    height: 80px !important;
  }

  &:hover {
    & > .iconRightArrow {
      transform: translateX(25%);
    }
  }

  & > .body {
    height: 1px;
    margin: -1px;
    position: absolute;
    width: 1px;

    &:not(.screenreaderOnly) {
      margin: 0;
    }

    @media (--viewport-xs) {
      margin: var(--size-sm) var(--size-md);
    }
  }
}

.body {
  padding: var(--size-sm);
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  overflow: hidden;
  word-wrap: normal;
}

.icon {
  transition: transform var(--duration-sm) ease-in-out;

  & > * {
    width: 100%;
    height: auto;
  }
}
