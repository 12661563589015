@value _listHeightDesktop: 480px;

.page {
  background-color: var(--color-gray-50);
  color: var(--color-black);
  position: relative;
  z-index: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: linear-gradient(58deg, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.7) 100%);
  }

  & > .fullscreenImage {
    margin-top: -18vw;
    flex: 1 0 auto;
    min-height: 280px;
    z-index: -2;

    @media (--viewport-md) {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      margin-top: 0;
    }
  }

  & > :not(:last-child) {
    @media (--viewport-md) {
      margin-bottom: var(--size-lg);
    }
  }

  & > .main {
    margin-top: -12.5vw;

    @media (--viewport-md) {
      margin-top: 0;
      position: absolute;
      top: calc(100% - _listHeightDesktop);
      min-height: _listHeightDesktop;
      left: 0;
      width: 100%;
    }
  }
}

.main {
  position: relative;
  z-index: 0;

  & > .waterLine {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: 0;

    @media (--viewport-md) {
      position: fixed;
      bottom: 0;
      top: unset;
    }
  }

  & > .rondleidingen {
    z-index: 1;
    width: 100%;

    @media (--viewport-md) {
      width: 1000px;
      max-width: 80vw;
      min-height: _listHeightDesktop;
      margin-left: auto;
    }
  }
}

.rondleidingen {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas:
    'blurp'
    'background-color';
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  color: var(--color-white);

  &::before {
    content: '';
    display: block;
    z-index: -1;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% / (750 / 94));
    grid-area: blurp;
    background-image: url(/images/text-curl.svg);
    background-size: cover;
    transform: translateY(1px);

    @media (--viewport-md) {
      padding-bottom: calc(100% / (2445 / 1149));
      background-image: url(/images/menu-blurp-desktop.svg);
    }
  }

  &::after {
    content: '';
    z-index: -1;
    display: block;
    grid-area: background-color;
    background-color: var(--color-turquoise-500);
  }

  & > .content {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    margin-top: auto;
  }
}

.content {
  padding: 10vw var(--size-md) var(--size-md);

  @media (--viewport-lg) {
    padding: 10vw 10vw var(--size-lg);
  }

  & > :first-child {
    margin-bottom: var(--size-sm);
  }
}

.list {
  & > :not(:last-child) {
    margin-bottom: var(--size-xs);
  }
}

.fullscreenImage {
  object-fit: cover;
}

.waterLine {
  color: var(--color-blue-500);
}

.mainBackground {
  color: var(--color-turquoise-500);
}
