.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-gray-90);

  & > .details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  & > .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .button {
    position: absolute;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: var(--color-white);
  padding: var(--size-lg);

  & > .text {
    margin-right: var(--size-md);
    flex: 1 1 auto;
    align-self: center;
  }
}

.image {
  position: relative;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.imageLayout {
  object-fit: cover;
}
