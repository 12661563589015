.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-90);
  color: var(--color-white);

  & > .image {
    height: 100%;
    flex: 1 1 auto;
    margin-bottom: calc(var(--size-lg) * -1);
  }

  & > .details {
    flex: 0 0 auto;
  }
}

.details {
  display: flex;
  z-index: 0;
  align-items: stretch;
  position: relative;

  & > .text {
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: 15rem;
    flex: 0 0 auto;
  }

  &::after {
    content: '';
    display: block;
    width: 10%;
    margin-top: var(--size-lg);
    background-size: 800px 500px;
    background-repeat: no-repeat;
  }
}

.text {
  padding: var(--size-sm) var(--size-md) var(--size-md);
  position: relative;

  &.white {
    color: var(--color-black);
    background-color: var(--color-white);
  }

  &.green {
    color: var(--color-white);
    background-color: var(--color-green-500);
  }

  &.blue {
    color: var(--color-white);
    background-color: var(--color-blue-500);
  }

  &.turquoise {
    color: var(--color-white);
    background-color: var(--color-turquoise-500);
  }

  @media (--viewport-md) {
    padding: var(--size-md) var(--size-lg) var(--size-lg);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }

  & > .curl {
    bottom: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    margin-bottom: -1px;
  }
}

.curl {
  &.white {
    color: var(--color-white);
  }

  &.green {
    color: var(--color-green-500);
  }

  &.blue {
    color: var(--color-blue-500);
  }

  &.turquoise {
    color: var(--color-turquoise-500);
  }
}

.image {
  position: relative;

  & > .imageLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
