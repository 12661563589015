.component {
  padding-left: var(--size-md);
  padding-right: var(--size-md);
  padding-top: var(--size-md);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  background: var(--color-white);

  @media (--viewport-md) {
    background: transparent;
    padding-left: var(--size-xl);
    padding-right: var(--size-xl);
    padding-top: var(--size-lg);
  }

  & > .logo {
    z-index: 1;
    width: 70px;
    position: absolute;
    right: var(--size-md);
    top: var(--size-md);

    @media (--viewport-md) {
      width: 100px;
    }
  }

  & > .topBackground {
    position: absolute;
    width: 100%;
    height: 65px;
    left: 0;
    bottom: -64px;
  }

  & > .background {
    position: absolute;
    bottom: -113px;
    left: calc(var(--size-lg) * -1);
  }

  & > .intro {
    @media (--viewport-md) {
      max-width: 550px;
    }
  }
}

.intro {
  position: relative;
  z-index: 0;

  & > .introDescription {
    margin-top: var(--size-md);
  }
}

.introContent {
  display: flex;

  @media (--viewport-md) {
    flex-wrap: wrap;
  }

  & > .introLabel {
    width: 150px;
    margin-top: 60px;
    margin-left: -100px;

    @media (--viewport-md) {
      width: 250px;
    }
  }
}

.introDescription {
  padding-right: 10vw;
}

.background {
  color: var(--color-white);
}

.curlWrapper {
  display: inline-flex;
  position: relative;

  & > .curl {
    position: absolute;
    bottom: 10px;
  }
}

.topBackground {
  color: white;
}

.curl {
  color: var(--color-blue-500);
}
