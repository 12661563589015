.component {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5vw 10vw calc(15vh + var(--size-md));
  overflow: hidden;

  @media (--viewport-md) {
    padding: 5vw 5vw calc(15vh + var(--size-md));
  }

  & > .map {
    width: 100%;
    max-width: var(--max-width);
    align-self: stretch;
  }

  & > .controls {
    position: absolute;
    right: var(--size-md);

    @media (--viewport-md) {
      right: var(--size-lg);
    }
  }
}

.map {
  position: relative;
  perspective: 3500px;
  perspective-origin: 0% 50%;
  transition: transform 0.8s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  & > .floors {
    width: 100%;
    height: 100%;
  }
}

.floors {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > .heading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  & > .floor {
    max-width: 100%;
    height: auto;
    position: absolute;
  }
}

.heading {
  text-align: right;
}

.floor {
  filter: drop-shadow(0 25px 50px rgba(0, 0, 0, 0.1));
  pointer-events: none;
}

.controls {
  border: 2px solid var(--color-gray-70);
  border-radius: var(--size-lg);
  transition: opacity var(--duration-sm);

  & > *:first-child {
    border-bottom: 2px solid var(--color-gray-70);
  }

  & > *:last-child {
    border-top: 2px solid var(--color-gray-70);
  }

  & > .controlButton {
    width: 40px;
    height: 40px;

    @media (--viewport-sm) {
      width: var(--size-xl);
      height: var(--size-xl);
    }
  }
}

.componentControlButton {
  color: var(--color-gray-70);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-xs);

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: var(--color-gray-90);
  }

  & > svg {
    width: 40px;
  }

  &[disabled] {
    pointer-events: none;
    color: var(--color-gray-60);
  }
}
