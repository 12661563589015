.componentBase {
  display: block;
  color: currentColor;
  line-height: 1;
  font-family: var(--font-family-base);
}

.componentSm {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-base-700);

  @media (--viewport-lg) {
    font-size: var(--font-size-24);
  }
}

.componentMd {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-base-600);

  @media (--viewport-lg) {
    font-size: var(--font-size-32);
  }
}

.componentLg {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-base-700);

  @media (--viewport-lg) {
    font-size: var(--font-size-52);
  }
}

.componentXl {
  font-size: var(--font-size-52);
  font-weight: var(--font-weight-base-700);

  @media (--viewport-lg) {
    font-size: var(--font-size-74);
  }
}

.componentXxl {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-base-700);

  @media (--viewport-lg) {
    font-size: var(--font-size-85);
  }
}
