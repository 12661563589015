.componentCallToActionBase {
  line-height: var(--line-height-normal);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-base-500);
  border-radius: 30px;
  font-size: var(--font-size-18);
  display: inline-flex;
  align-items: center;
  padding: var(--size-xs) var(--size-lg);

  & > .text {
    margin-right: var(--size-xs);
  }

  & > .iconRight {
    margin-left: 2rem;
  }
}

.componentCallToActionWhite {
  color: var(--color-black);
  background-color: var(--color-white);
}

.componentCallToActionTransparent {
  color: var(--color-white);
  font-size: var(--font-size-18);
  padding: 0;

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }

  @media (--viewport-lg) {
    font-size: var(--font-size-32);
  }
}

.text {
  display: flex;
  flex-direction: column;
}

.iconRight {
  transition: transform var(--duration-sm) ease-in-out;

  & > * {
    width: 100%;
    height: auto;
  }
}
