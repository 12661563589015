.component {
  background: var(--color-white);
  padding: var(--size-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-black);
  border-radius: var(--size-xs);

  @media (--viewport-md) {
    padding-left: var(--size-lg);
  }

  & > .button {
    flex: 0 0 auto;
  }

  & > .header {
    flex: 1 1 auto;
  }
}

.header {
  & > :not(:first-child) {
    margin-top: var(--size-xs);
  }
}

.description {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base-400);
  line-height: 1;
}

.button {
  display: flex;
  justify-content: flex-end;
}
