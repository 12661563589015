.component {
  display: flex;
  justify-content: center;
  pointer-events: none;

  & > .grid {
    width: 100%;
    max-width: calc(var(--max-width) + 36rem);
    pointer-events: auto;
  }
}

.grid {
  display: grid;
  grid-template-columns:
    [break-out-left]
    minmax(var(--size-md), 1fr)
    [content-start]
    minmax(auto, var(--max-width))
    [content-end]
    minmax(var(--size-md), 1fr)
    [break-out-right];
  pointer-events: none;

  @media (--viewport-md) {
    grid-template-columns:
      [break-out-left]
      minmax(var(--size-lg), 1fr)
      [content-start]
      minmax(auto, var(--max-width))
      [content-end]
      minmax(var(--size-lg), 1fr)
      [break-out-right];
  }

  & > * {
    grid-column: content-start / content-end;
    pointer-events: auto;
    width: 100%;
    min-width: 0;
  }

  &.breakOutLeft {
    & > * {
      grid-column: break-out-left / content-end;
    }
  }

  &.breakOutRight {
    & > * {
      grid-column: content-start / break-out-right;
    }
  }
}
