:root {
  --color-gray-10: #f8f8f8;
  --color-gray-20: #efefef;
  --color-gray-50: #eee;
  --color-gray-60: #999;
  --color-gray-70: #4d4d4d;
  --color-gray-80: #333;
  --color-gray-90: #1c1c1c;
  --color-black: #111;
  --color-white: #fff;
  --color-purple: #5e0cff;
  --color-cyan: #00f4ff;
  --color-green: #0f0;

  --color-turquoise-500: #0a9390;
  --color-orange-500: #ff8556;
  --color-blue-500: #3694d0;
  --color-green-500: #03853e;
}
