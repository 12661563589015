.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  background: var(--color-gray-90);

  & > .image {
    height: 100%;
    flex: 1 1 auto;
    margin-bottom: calc(var(--size-lg) * -1);
  }

  & > .details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.details {
  padding: var(--size-md);

  @media (--viewport-md) {
    padding: var(--size-lg);
  }
}

.image {
  position: relative;

  & > .imageLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


