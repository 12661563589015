.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .button {
    position: absolute;
    left: var(--size-md);
    top: var(--size-md);
    z-index: 1;
  }

  & > .storyTeaser {
    position: absolute;
    bottom: calc(10vh + var(--size-md));
    width: 100%;
    right: 0;
  }

  & > .heading {
    position: absolute;
    top: var(--size-md);
    right: var(--size-md);
    z-index: 1;
  }

  & > * {
    flex-grow: 1;
  }
}

.heading {
  color: var(--color-white);
}
