:root {
  --size-xs: 0.5rem;
  --size-sm: 1rem;
  --size-sm2: 1.5rem;
  --size-md: 2rem;
  --size-lg: 4rem;
  --size-xl: 8rem;
  --size-xxl: 25rem;
  --max-width: 72rem;
}
