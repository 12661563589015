.component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px !important;
  height: 1px !important;
  -webkit-tap-highlight-color: transparent;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.1));

  &:focus {
    outline: 0;
  }

  & > .layout {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    width: 30px !important;
    height: 40px !important;
  }
}

.layout {
  position: relative;
  display: flex;
  justify-content: center;

  &.isActive {
    transform: scale(2);
  }

  & > .icon {
    position: absolute;
    top: 15%;
  }
}

.icon {
  color: var(--color-black);

  & > svg {
    width: 15px;
    height: 15px;

    @media (--viewport-lg) {
      width: 18px;
      height: 18px;
    }
  }

  &.isActive {
    transform: scale(1.75);
  }
}

.outer,
.icon {
  display: block;
}

.outer {
  color: var(--color-white);
  transition: color var(--duration-sm) var(--ease-in-out-functional);

  &.isActive {
    color: var(--color-orange-500);
    transform: scale(1);

    & > svg {
      width: 30px;
      height: 30px;
    }
  }

  & > svg {
    width: 100%;
    height: auto;
  }
}


