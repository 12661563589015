.componentBase {
  font-weight: var(--font-weight-base-400);
  line-height: var(--line-height-normal);
}

.componentRegular {
  font-size: var(--font-size-18);

  @media (--viewport-sm) {
    font-size: var(--font-size-24);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }
}

.componentSmall {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-base-500);

  @media (--viewport-sm) {
    font-size: var(--font-size-18);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-xs);
  }
}
